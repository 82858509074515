import { ReactNode } from "react"
import cx from "classnames"

interface PartnersContainerProps {
  children: ReactNode
  className?: string
}

const PartnersContainer = ({ children, className }: PartnersContainerProps) => (
  <div
    className={cx(
      "bg-gray-100 min-h-[calc(100vh-4rem)] flex-auto flex flex-col",
      className
    )}
  >
    {children}
  </div>
)

export default PartnersContainer
