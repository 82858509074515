import { QueryStatus } from "@tanstack/react-query"

import { Spinner } from "../../components/Spinner"
import PartnersAccountOverflowContainer from "./PartnersAccountOverflowContainer"
import { AttestationType } from "@/types/attestations"

interface PartnersAccountAttestationsTypes {
  attestations: AttestationType[] | undefined
  accountStatus: QueryStatus
}

const PartnersAccountAttestations = ({
  attestations,
  accountStatus,
}: PartnersAccountAttestationsTypes) => {
  if (attestations?.length === 0) {
    return null
  }

  if (accountStatus === "pending") {
    return (
      <PartnersAccountOverflowContainer>
        <h4 className="sticky -top-4 left-0 right-0 bg-cloud-50 text-overline text-dusk-500 p-4 -m-4 mb-0 border-b border-b-cloud-500 z-2">
          Attestations
        </h4>

        <div className="flex justify-center items-center h-24">
          <Spinner />
        </div>
      </PartnersAccountOverflowContainer>
    )
  }

  return (
    <PartnersAccountOverflowContainer>
      <h4 className="sticky -top-4 left-0 right-0 bg-cloud-50 text-overline text-dusk-500 p-4 -m-4 mb-0 border-b border-b-cloud-500 z-2">
        Attestations
      </h4>

      {attestations?.map((attestation) => (
        <div
          className="bg-white border-cloud-500 rounded-md border p-4"
          key={attestation.attestation_id}
        >
          <p>
            <span className="font-semibold">Question: </span>{" "}
            {attestation.attestation}
          </p>
          <p>
            <span className="font-semibold">Answer: </span> {attestation.value}
          </p>
        </div>
      ))}
    </PartnersAccountOverflowContainer>
  )
}

export default PartnersAccountAttestations
