import { useEffect, useState, useCallback, useMemo } from "react"
import { QueryStatus, useQueryClient } from "@tanstack/react-query"
import MapGL, { Source, Layer } from "@urbica/react-map-gl"
import {
  FeatureCollection,
  Geometry,
  MultiPolygon,
  Properties,
} from "@turf/helpers"
import debounce from "lodash/debounce"

import { LayerPicker } from "../../components/LayerPicker"
import { Spinner } from "../../components/Spinner"
import EligibilityLayer, {
  ToggleEligibilityLayer,
} from "../../components/EligibilityLayer"
import PartnersDownloadPropertyBoundaries from "./PartnersDownloadPropertyBoundaries"
import { MAPBOX_TOKEN, mapStyles } from "../../shared/constants"
import { getViewportFromFeature } from "../ProjectDetails/ProjectMap/helpers"
import ProjectMapParcels from "../ProjectDetails/ProjectMap/ProjectMapParcels"
import { useAccessToken } from "../../stores"
import {
  useGetPartnerTableRowParcelAssessment,
  useGetPartnerTableRowTileUrls,
} from "../../hooks"
import { usePartnersStore } from "../../stores/usePartnersStore"
import "mapbox-gl/dist/mapbox-gl.css"
import { getViewportFromFeatures, transformBounds } from "../../shared/utils"
import { ViewportTypes } from "../../types"
import { TileURLsTypes } from "../../types/tiles"
import { LayerType } from "../../types/mapbox"
import CoordinatesPopup from "./PartnersAccountMap/CoordinatesPopup"
import {
  MapGlOnMouseDownEventTypes,
  PopupCoordinatesTypes,
} from "./PartnersAccountMap/types"

interface PartnersAccountMapTypes {
  accountStatus: QueryStatus
  bounds: string
}

const PartnersAccountMap = ({
  accountStatus,
  bounds,
}: PartnersAccountMapTypes) => {
  const queryClient = useQueryClient()
  const accessToken = useAccessToken()
  const [layer, setLayer] = useState<LayerType>("aerial")
  const [activeFeature, setActiveFeature] = useState<number | null>(null)
  const [showEligibilityLayer, setShowEligibilityLayer] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [popupCoordinates, setPopupCoordinates] =
    useState<PopupCoordinatesTypes>({
      lat: null,
      lng: null,
    })
  const [parcelData, setParcelData] = useState<FeatureCollection<
    Geometry,
    Properties
  > | null>(null)
  const [tileData, setTileData] = useState<TileURLsTypes | null>(null)

  const { viewport, activeRow, setViewport } = usePartnersStore()

  const { mutateAsync: getTileUrls } = useGetPartnerTableRowTileUrls(
    queryClient,
    activeRow?.id as number,
    {
      onSuccess: (data: TileURLsTypes) => {
        setTileData(data)
      },
      onError: () => {
        setTileData(null)
      },
    }
  )

  const handleMapGLOnMouseDown = useCallback(
    (e: MapGlOnMouseDownEventTypes) => {
      if (e.originalEvent.button === 2) {
        setPopupCoordinates(e.lngLat)
        setShowPopup(true)
      } else if (e.originalEvent.button === 0) {
        setShowPopup(false)
      }
    },
    []
  )

  const handleMapGLTransformRequest = useCallback(
    (url: string) => {
      if (url.includes("tipg") || url.includes("amazonaws.com/collections")) {
        return {
          url,
          headers: { Authorization: `Bearer ${accessToken}` },
          credentials: "include",
        }
      }
      return { url }
    },
    [accessToken]
  )

  const handleLayerChange = (newLayer: LayerType) => {
    setLayer(newLayer)
  }

  const { mutateAsync: getParcelAssessment } =
    useGetPartnerTableRowParcelAssessment({
      onSuccess: (data: FeatureCollection<Geometry, Properties>) => {
        queryClient.setQueryData(
          [
            "partners",
            "ncapi",
            "parcel",
            "assessment",
            "ap",
            activeRow?.id?.toString(),
          ],
          data
        )
        if (activeFeature === null) {
          setActiveFeature(0)
        }
        setParcelData(data)
        setViewport(getViewportFromFeature(data.features[0]))
      },
      onError: () => {
        setActiveFeature(null)
        setParcelData(null)
        // DEV: fallback to just property bounds if something fails getting parcel-level data
        setViewport(
          getViewportFromFeatures(
            transformBounds(JSON.parse(bounds) as MultiPolygon)
          ) as ViewportTypes
        )
      },
    })

  const processBounds = useMemo(
    () =>
      debounce((boundsString: string) => {
        const parsedBounds = JSON.parse(boundsString)
        const numVertices = parsedBounds?.coordinates?.flat(2).length
        // too many vertices, we crash trying to load parcel-level data so just show the property bounds
        if (numVertices >= 150) {
          // reset data, else it is carried over from previous selection
          setParcelData(null)
          setTileData(null)
          // just show property bounds, not parcel-level data
          setViewport(
            getViewportFromFeatures(
              transformBounds(parsedBounds as MultiPolygon)
            ) as ViewportTypes
          )
        } else if (activeRow?.id !== undefined) {
          getTileUrls(activeRow.id)
          getParcelAssessment(activeRow.id)
        }
      }, 500),
    [getTileUrls, getParcelAssessment, setViewport, activeRow]
  )

  useEffect(() => {
    setActiveFeature(null)

    if (activeRow?.id && bounds) {
      processBounds(bounds)
    }
  }, [activeRow, bounds, processBounds])

  const showEligibilityOption = !!parcelData && !!tileData

  if (viewport === null || accountStatus === "pending") {
    return (
      <div className="relative h-[656px] flex justify-center items-center bg-white rounded-md shadow-sm overflow-hidden mb-6 p-0">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="partners-account-map relative bg-white rounded-md shadow-sm overflow-hidden mb-6 p-0">
      <div className="lg:flex relative overflow-hidden">
        <div
          className="map w-full h-[650px] relative"
          onContextMenu={(e) => e.preventDefault()}
        >
          <MapGL
            style={{ width: "100%", height: "650px" }}
            mapStyle={mapStyles[layer as keyof typeof mapStyles].url}
            accessToken={MAPBOX_TOKEN}
            onViewportChange={setViewport}
            viewportChangeMethod="flyTo"
            viewportChangeOptions={{
              duration: 1000,
            }}
            dragRotate={false}
            onMousedown={handleMapGLOnMouseDown}
            transformRequest={handleMapGLTransformRequest}
            {...viewport}
          >
            <Source
              id="route"
              type="geojson"
              data={
                activeFeature !== null
                  ? {
                      type: "FeatureCollection",
                      features: parcelData?.features,
                    }
                  : JSON.parse(bounds)
              }
            />

            <Layer
              id="route"
              source="route"
              type="line"
              paint={{
                "line-color": "#3bb2d0",
                "line-width": 3,
              }}
            />

            <EligibilityLayer
              showEligibilityLayer={showEligibilityLayer}
              tileData={tileData}
            />

            <CoordinatesPopup
              show={showPopup}
              popupCoordinates={popupCoordinates}
            />
          </MapGL>

          <ToggleEligibilityLayer
            show={showEligibilityOption}
            value={showEligibilityLayer}
            label={
              <>
                Show <span className="font-normal">Eligibility</span>
              </>
            }
            onChange={() => setShowEligibilityLayer((oldValue) => !oldValue)}
          />

          <LayerPicker
            layer={layer}
            onLayerChange={handleLayerChange}
            className="top-4 right-4 hidden sm:block"
          />
        </div>

        <ProjectMapParcels
          show={showEligibilityOption}
          isIneligible={false}
          parcelData={parcelData}
          activeFeature={activeFeature}
          setActiveFeature={setActiveFeature}
          setViewport={setViewport}
        />
      </div>

      <PartnersDownloadPropertyBoundaries apId={activeRow?.id as number} />
    </div>
  )
}

export default PartnersAccountMap
