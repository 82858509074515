import { CSSProperties } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSort } from "@fortawesome/pro-duotone-svg-icons"
import { flexRender, Header } from "@tanstack/react-table"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import cx from "classnames"

interface DraggableTableHeaderTypes<TData> {
  header: Header<TData, unknown>
  i: number
  arr: Header<TData, unknown>[]
}

const sortStyle = {
  false: {
    "--fa-primary-opacity": 0.4,
    "--fa-secondary-opacity": 0.4,
  } as CSSProperties,
  asc: {
    "--fa-primary-opacity": 1,
    "--fa-secondary-opacity": 0.4,
  } as CSSProperties,
  desc: {
    "--fa-primary-opacity": 0.4,
    "--fa-secondary-opacity": 1,
  } as CSSProperties,
}

const DraggableTableHeader = <TData,>({
  header,
  i,
  arr,
}: DraggableTableHeaderTypes<TData>) => {
  const { attributes, isDragging, listeners, setNodeRef, transform } =
    useSortable({
      id: header.column.id,
    })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition: "width transform 0.2s ease-in-out",
    width: header.column.getSize(),
  }

  return (
    <th
      ref={setNodeRef}
      className={cx(
        "relative text-dusk-500 text-sm font-semibold leading-4 tracking-[0.01em] whitespace-nowrap border-b border-b-cloud-500 px-3 py-3 lg:px-5 lg:py-4",
        { "md:pl-6!": i === 0 },
        { "md:pr-6!": i === arr.length - 1 },
        {
          "text-right":
            header.column.columnDef.header === "Acreage" ||
            header.column.columnDef.header === "Eligible Acres",
        },
        { "z-1 opacity-80": isDragging }
      )}
      style={style}
    >
      {" "}
      {header.column.columnDef.id === "select" ? (
        flexRender(header.column.columnDef.header, header.getContext())
      ) : (
        <button {...attributes} {...listeners}>
          {flexRender(header.column.columnDef.header, header.getContext())}
        </button>
      )}
      {header.column.getCanSort() && (
        <FontAwesomeIcon
          icon={faSort}
          className="cursor-pointer inline-block w-4 h-4 ml-1"
          style={
            sortStyle[header.column.getIsSorted() as "false" | "asc" | "desc"]
          }
          onClick={header.column.getToggleSortingHandler()}
        />
      )}
    </th>
  )
}

export default DraggableTableHeader
