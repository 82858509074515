import { components, ClearIndicatorProps } from "react-select"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/pro-duotone-svg-icons"

const ClearIndicator = (props: ClearIndicatorProps) => (
  <components.ClearIndicator {...props}>
    <FontAwesomeIcon icon={faXmark} />
  </components.ClearIndicator>
)

export default ClearIndicator
