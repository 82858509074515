import { useQueryClient } from "@tanstack/react-query"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"

import { usePartnersTablePagination } from "../../../hooks"

interface TablePaginationTypes {
  previous: string | null | undefined
  next: string | null | undefined
  enabled: boolean
  isTableLoading: boolean
}

const TablePagination = ({
  previous,
  next,
  enabled = false,
  isTableLoading,
}: TablePaginationTypes) => {
  const queryClient = useQueryClient()

  const { mutateAsync: setTablePage } = usePartnersTablePagination(queryClient)

  const handleSetTablePage = async (page: string) => {
    await setTablePage(page)
  }

  if (!enabled) {
    return null
  }

  const disabledClass = "flex justify-center items-center text-grass-200"
  const linkClass =
    "flex justify-center items-center text-leaf hover:text-grass-900 active:text-grass-900 transition-colors duration-200 ease-[cubic-bezier(0.4, 0, 0.2, 1)]"
  const linkIconClass =
    "flex w-10 h-10 border border-solid border-charcoal-50 rounded-full justify-center items-center"

  return (
    <div className="h-[72px] flex justify-center items-center gap-16">
      <button
        type="button"
        aria-label="previous page"
        className={cx(
          "h-10",
          previous === null || isTableLoading ? disabledClass : linkClass
        )}
        onClick={() => {
          if (previous) {
            handleSetTablePage(previous)
          }
        }}
        disabled={previous === null || isTableLoading}
      >
        <span className="flex items-center gap-2">
          <span className={linkIconClass}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </span>

          <span className="text-base leading-[1.50] tracking-[0.02em] font-semibold">
            Prev page
          </span>
        </span>
      </button>

      <button
        type="button"
        aria-label="next page"
        className={cx(
          "h-10",
          next === null || isTableLoading ? disabledClass : linkClass
        )}
        onClick={() => {
          if (next) {
            handleSetTablePage(next)
          }
        }}
        disabled={next === null || isTableLoading}
      >
        <span className="flex items-center gap-2">
          <span className="text-base leading-[1.50] tracking-[0.02em] font-semibold">
            Next page
          </span>

          <span className={linkIconClass}>
            <FontAwesomeIcon icon={faArrowRight} />
          </span>
        </span>
      </button>
    </div>
  )
}

export default TablePagination
