import { QueryClient, UseMutationResult } from "@tanstack/react-query"
import { useMutationWithRefresh } from "../utils"
import { getPartnersLandownerGeodownload } from "../../../api/data"
import { UseMutationWithRefreshConfig } from "../utils/useMutationWithRefresh"
import { PropertyBoundariesFileFormat } from "@/types"

export interface GetPartnersLandownerGeodownloadProps {
  apId: number
  fileFormat: PropertyBoundariesFileFormat
}

export const useGetPartnersLandownerGeodownload = <
  TData,
  TError,
  TVariables extends GetPartnersLandownerGeodownloadProps,
>(
  queryClient: QueryClient,
  apId: number,
  config: UseMutationWithRefreshConfig<TData, TError, TVariables> = {}
): UseMutationResult<TData, TError, TVariables, unknown> => {
  return useMutationWithRefresh<TData, TError, TVariables, unknown>(
    queryClient,
    ({ apId, fileFormat }: GetPartnersLandownerGeodownloadProps) =>
      getPartnersLandownerGeodownload(apId.toString(), fileFormat),
    {
      _onSuccess: (data) => {
        queryClient.setQueryData(["partners", "ap", apId, "geodownload"], data)
      },
      ...config,
    }
  )
}
