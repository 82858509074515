import cx from "classnames"

export interface RadioInputProps {
  value: string | number
  checked: boolean
  onChange: (event: string | number) => void
  label: string
}

const RadioInput = ({ value, checked, onChange, label }: RadioInputProps) => (
  <label className="flex flex-row items-center gap-1 hover:cursor-pointer">
    <input
      type="radio"
      value={value}
      checked={checked}
      onChange={(event) => onChange(event.target.value)}
      className={cx(
        "border-none relative w-5 h-5",
        "before:w-5 before:h-5 before:top-0 before:left-0 before:absolute before:rounded-full before:border-2 before:border-grass-900",
        "hover:border-none hover:ring-0 hover:ring-offset-0 hover:shadow-none hover:cursor-pointer",
        "focus:border-none focus:ring-0 focus:ring-offset-0 focus:shadow-none",
        "active:border-none active:ring-0 active:ring-offset-0 active:shadow-none",
        "checked:border-none checked:bg-transparent checked:rounded-none checked:shadow-none",
        "checked:hover:border-none checked:hover:bg-transparent",
        "checked:focus:border-none checked:focus:bg-transparent",
        "checked:active:border-none checked:active:bg-transparent",
        "checked:after:w-2.5 checked:after:h-2.5 checked:after:absolute checked:after:bg-grass-900 checked:after:rounded-full checked:after:top-[5px] checked:after:left-[5px]"
      )}
    />
    {label}
  </label>
)

export default RadioInput
