import { AxiosError } from "axios"
import { useQueryClient } from "@tanstack/react-query"
import cx from "classnames"

import PartnersQualifiedLeads from "./PartnersQualifiedLeads"
import PartnersEligibleLeads from "./PartnersEligibleLeads"
import { useGetPartnersLandowner } from "../../hooks"
import { usePartnersStore } from "../../stores/usePartnersStore"
import { ELIGIBLE_LEADS, QUALIFIED_LEADS } from "../../shared/constants"
import {
  EligibleLeadsTypes,
  PartnersLandownerTypes,
  ReachoutsCounterTypes,
  RowsType,
} from "@/types/partners"
import { Profile } from "@/types"
import { DialogStateReturn } from "reakit/Dialog"

interface TabItemTypes {
  text: string
  currentTab: "qualified_leads" | "eligible_leads"
  activeTab: "qualified_leads" | "eligible_leads"
  onClick: () => void
}

interface PartnersTabsTypes {
  partnerDashboardData: RowsType | undefined
  profile: Profile | undefined
  eligibleLeads: EligibleLeadsTypes | undefined
  inviteDealOwnerDialog: DialogStateReturn
  editDealOwnerDialog: DialogStateReturn
  reachoutsCounter: ReachoutsCounterTypes | undefined
}

const TabItem = ({ text, currentTab, activeTab, onClick }: TabItemTypes) => (
  <button
    className={cx(
      "text-overline normal-case py-6 relative focus:z-2 first:rounded-tl last:rounded-tr",
      "text-base font-bold leading-[130%] tracking-[0.32px] normal-case px-6 pt-4 pb-2",
      currentTab === activeTab
        ? "text-white bg-grass-500 border-b-8 border-grass-900"
        : "text-dusk hover:text-dusk-900 border-b-4 border-transparent transition duration-200"
    )}
    type="button"
    onClick={onClick}
  >
    {text}
  </button>
)

const PartnersTabs = ({
  partnerDashboardData,
  profile,
  eligibleLeads,
  inviteDealOwnerDialog,
  editDealOwnerDialog,
  reachoutsCounter,
}: PartnersTabsTypes) => {
  const queryClient = useQueryClient()

  const {
    activeTab,
    activeRow,
    eligibleActiveRowUnlocked,
    setActiveTab,
    setActiveRow,
  } = usePartnersStore()

  const showReachoutTab =
    reachoutsCounter && reachoutsCounter?.max_reachouts > 0

  const { data: account, status: accountStatus } = useGetPartnersLandowner<
    PartnersLandownerTypes,
    AxiosError
  >(queryClient, activeRow?.id as number, {
    enabled:
      activeTab === ELIGIBLE_LEADS
        ? eligibleActiveRowUnlocked && activeRow?.id !== undefined
        : activeRow?.id !== undefined,
  })

  return (
    <>
      {profile?.is_partner ? (
        <div className="flex mb-6">
          <div className="self-start flex bg-white shadow-sm rounded-t">
            <TabItem
              currentTab={QUALIFIED_LEADS}
              activeTab={activeTab}
              text="Interested Landowners"
              onClick={() => {
                setActiveTab(QUALIFIED_LEADS)
                setActiveRow(partnerDashboardData?.[0] || null)
              }}
            />

            {showReachoutTab && (
              <TabItem
                currentTab={ELIGIBLE_LEADS}
                activeTab={activeTab}
                text="Send Landowner Invitations"
                onClick={() => {
                  setActiveTab(ELIGIBLE_LEADS)
                }}
              />
            )}
          </div>
        </div>
      ) : null}

      <PartnersQualifiedLeads
        partnerDashboardData={partnerDashboardData}
        profile={profile}
        inviteDealOwnerDialog={inviteDealOwnerDialog}
        editDealOwnerDialog={editDealOwnerDialog}
        account={account}
        accountStatus={accountStatus}
      />

      {profile?.is_partner ? (
        <PartnersEligibleLeads
          eligibleLeads={eligibleLeads}
          userFirstName={profile?.first_name}
          account={account}
          accountStatus={accountStatus}
          reachoutsCounter={reachoutsCounter}
        />
      ) : null}
    </>
  )
}

export default PartnersTabs
