import { AxiosError } from "axios"
import { useQueryClient } from "@tanstack/react-query"

import PartnersAccountContainer from "./PartnersAccountContainer"
import { useGetPartnersInterestedProjects } from "../../hooks"
import { usePartnersStore } from "../../stores/usePartnersStore"

const PartnersInterestedPrograms = () => {
  const queryClient = useQueryClient()

  const { activeRow } = usePartnersStore()

  const { data: interestedProjects } = useGetPartnersInterestedProjects<
    string[],
    AxiosError
  >(queryClient, activeRow?.id as number, {
    enabled: activeRow?.id !== undefined,
  })

  if (interestedProjects === undefined || interestedProjects?.length === 0) {
    return null
  }

  return (
    <PartnersAccountContainer>
      <h4 className="text-overline text-dusk-500 mb-3">Interested Programs</h4>

      <ul>
        {interestedProjects?.map((program) => (
          <li
            key={program}
            className="block text-leaf text-sm font-bold leading-[130%] tracking-[0.14px]"
          >
            {program}
          </li>
        ))}
      </ul>
    </PartnersAccountContainer>
  )
}

export default PartnersInterestedPrograms
