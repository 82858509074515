import { AxiosError } from "axios"
import { useQueryClient } from "@tanstack/react-query"
import { faMap } from "@fortawesome/pro-solid-svg-icons"

import IconButton from "../../components/IconButton"
import { Toast } from "../../components/Toast"
import RadioInput from "../../components/RadioInput"
import { useGetPartnersLandownerGeodownload } from "../../hooks"
import { GetPartnersLandownerGeodownloadProps } from "../../hooks/react-query/mutations/useGetPartnersLandownerGeodownload"
import useLocalStorage from "../../hooks/useLocalStorage"
import { genericErrMsg } from "../../api/auth"
import { GeoDownloadResponseTypes } from "@/types/partners"
import { PropertyBoundariesFileFormat } from "@/types"

interface PartnersDownloadPropertyBoundariesTypes {
  apId: number
}

const PartnersDownloadPropertyBoundaries = ({
  apId,
}: PartnersDownloadPropertyBoundariesTypes) => {
  const [fileFormat, setFileFormat] =
    useLocalStorage<PropertyBoundariesFileFormat>("fileFormat", "gpkg")

  const queryClient = useQueryClient()

  const { mutateAsync: boundsGeodownload } = useGetPartnersLandownerGeodownload<
    GeoDownloadResponseTypes,
    AxiosError,
    GetPartnersLandownerGeodownloadProps
  >(queryClient, apId, {
    onSuccess: ({ download_link }) => {
      window.location.href = download_link
    },
    onError: (error: AxiosError) => {
      Toast.error(error?.message || genericErrMsg)
    },
  })

  const handleBoundsGeodownload = async () => {
    await boundsGeodownload({
      apId,
      fileFormat: fileFormat,
    })
  }

  return (
    <div className="w-full flex flex-col md:flex-row md:justify-between md:items-center gap-4 p-4">
      <IconButton
        onClick={() => {
          handleBoundsGeodownload()
        }}
        icon={faMap}
        text="Download Property Boundaries"
        className="leading-6 text-left"
      />

      <form className="flex items-center gap-3">
        <p className="font-semibold leading-6">File format:</p>

        <RadioInput
          value="gpkg"
          checked={fileFormat === "gpkg"}
          onChange={() => setFileFormat("gpkg")}
          label="gpkg"
        />

        <RadioInput
          value="kml"
          checked={fileFormat === "kml"}
          onChange={() => setFileFormat("kml")}
          label="kml"
        />

        <RadioInput
          value="kmz"
          checked={fileFormat === "kmz"}
          onChange={() => setFileFormat("kmz")}
          label="kmz"
        />
      </form>
    </div>
  )
}
export default PartnersDownloadPropertyBoundaries
