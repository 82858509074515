import { QueryStatus } from "@tanstack/react-query"

import { Profile } from "@/types"
import { Spinner } from "../../components/Spinner"
import PartnersAccountOverflowContainer from "./PartnersAccountOverflowContainer"

interface PartnersAccountProfileTypes {
  profiles: Profile[] | undefined
  accountStatus: QueryStatus
}

const PartnersAccountProfile = ({
  profiles,
  accountStatus,
}: PartnersAccountProfileTypes) => {
  if (accountStatus === "pending") {
    return (
      <PartnersAccountOverflowContainer>
        <h4 className="sticky -top-4 left-0 right-0 bg-cloud-50 text-overline text-dusk-500 p-4 -m-4 mb-0 border-b border-b-cloud-500 z-2">
          All Contact Info
        </h4>

        <div className="flex justify-center items-center h-[130px]">
          <Spinner />
        </div>
      </PartnersAccountOverflowContainer>
    )
  }

  return (
    <PartnersAccountOverflowContainer>
      <h4 className="sticky -top-4 left-0 right-0 bg-cloud-50 text-overline text-dusk-500 p-4 -m-4 mb-0 border-b border-b-cloud-500 z-2">
        All Contact Info
      </h4>

      {profiles?.map((profile) => (
        <div
          className="bg-white border-cloud-500 rounded-md border p-4"
          key={profile.id}
        >
          <ul>
            <li>
              <span className="font-semibold">First name - </span>
              {profile?.first_name}
            </li>

            <li>
              <span className="font-semibold">Last name - </span>
              {profile?.last_name}
            </li>

            <li>
              <span className="font-semibold">Email - </span>
              {profile?.email ? (
                <a href={`mailto:${profile?.email}`}>{profile?.email}</a>
              ) : null}
            </li>

            <li>
              <span className="font-semibold">Phone Number - </span>
              {profile?.phone_number}
            </li>
          </ul>
        </div>
      ))}
    </PartnersAccountOverflowContainer>
  )
}

export default PartnersAccountProfile
