import { CSSProperties } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilter } from "@fortawesome/pro-duotone-svg-icons"
import { PopoverDisclosure, PopoverProps } from "reakit/Popover"
import {
  Tooltip,
  TooltipArrow,
  TooltipReference,
  TooltipState,
} from "reakit/Tooltip"
import cx from "classnames"

interface TableFiltersCloseTypes {
  popover: PopoverProps
  tooltip: TooltipState
  columnFiltersLength: number | undefined
}

const TableFiltersClose = ({
  popover,
  tooltip,
  columnFiltersLength = 0,
}: TableFiltersCloseTypes) => {
  const filterText = columnFiltersLength > 1 ? "Filters" : "Filter"

  return (
    <PopoverDisclosure
      {...popover}
      className={cx(
        "btn2 btn2-outline-primary text-charcoal-500 hover:text-charcoal-500! px-2",
        {
          "bg-cloud-500": columnFiltersLength > 0,
        }
      )}
    >
      <TooltipReference {...tooltip} className="flex items-center gap-1">
        Filter
        <FontAwesomeIcon
          icon={faFilter}
          style={
            {
              "--fa-primary-opacity": 0,
              "--fa-secondary-opacity": 1,
            } as CSSProperties
          }
        />
      </TooltipReference>

      {!popover.visible && columnFiltersLength > 0 ? (
        <Tooltip {...tooltip} className="st-tooltip">
          <div className="text-sm leading-[1.50] tracking-[0.01em] max-w-[664px] p-3 st-tooltip-inner">
            <TooltipArrow {...tooltip} className="fill-charcoal-500" />
            {columnFiltersLength} {filterText}
          </div>
        </Tooltip>
      ) : null}
    </PopoverDisclosure>
  )
}

export default TableFiltersClose
