import { ReactNode } from "react"

interface PartnersAccountOverflowContainerProps {
  children: ReactNode
}

const PartnersAccountOverflowContainer = ({
  children,
}: PartnersAccountOverflowContainerProps) => (
  <div className="relative w-full rounded-md p-4 bg-white shadow-sm flex flex-col gap-5 self-start overflow-auto max-h-[366px]">
    {children}
  </div>
)

export default PartnersAccountOverflowContainer
