import { useState } from "react"
import { FormikValues, useField, useFormikContext } from "formik"
import cx from "classnames"
import CreatableSelect from "react-select/creatable"

import DropdownIndicator from "./DropdownIndicator"
import ClearIndicator from "./ClearIndicator"
import { createOption, defaultOptions, selectStyles } from "./helpers"
import { InputSelectProps } from "./types"

const InputSelect = ({
  name,
  label,
  labelClass,
  options,
  isValidNewOption = (inputValue) => inputValue.length > 0,
  className,
  isClearable,
  ...props
}: InputSelectProps) => {
  const [selectOptions, setSelectOptions] = useState(defaultOptions(options))
  const [field] = useField(name)
  const { setFieldValue, errors } = useFormikContext<FormikValues>()
  const isError = errors[name] !== undefined

  return (
    <>
      {label && (
        <label className={cx(labelClass ? labelClass : "inline")}>
          {label}
        </label>
      )}
      <CreatableSelect
        {...props}
        name={name}
        value={field.value}
        isValidNewOption={isValidNewOption}
        onCreateOption={(inputValue) => {
          const newOption = createOption(inputValue)
          setSelectOptions((prev) => [...prev, newOption])
          setFieldValue(name, newOption)
        }}
        onChange={(newValue) => {
          setFieldValue(name, newValue)
        }}
        options={selectOptions}
        components={{
          DropdownIndicator: DropdownIndicator,
          ClearIndicator: ClearIndicator,
        }}
        styles={selectStyles(isError, isClearable)}
        className={cx("[&_input[type='text']:focus:shadow-none", className)}
        isClearable={isClearable}
        menuPortalTarget={document.body}
        menuPosition="fixed"
      />
      {errors[name] !== undefined ? (
        <div className="text-sm leading-[1.50] tracking-[0.01em] text-red-600 mt-1">
          {errors[name] as string}
        </div>
      ) : null}
    </>
  )
}

export default InputSelect
